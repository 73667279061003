<template>
  <Teleport to="body">
    <dialog
      ref="dialogRef"
      class="group invisible fixed z-[999] m-0 flex h-dvh max-h-[100vh] w-dvw max-w-[100vw] items-center justify-center bg-transparent p-4 opacity-0 transition-opacity duration-200 backdrop:bg-black/60 open:pointer-events-auto open:visible open:opacity-100"
      @close="close"
    >
      <button class="sr-only" type="button" @click="close">Close</button>
      <VideoPlayer
        :id="id"
        ref="playerRef"
        class="max-w-7xl flex-1 scale-95 overflow-hidden rounded-lg transition-transform group-open:scale-100"
      />
    </dialog>
  </Teleport>
</template>

<script setup lang="ts">
import { useBodyScrollLock } from "radix-vue";
import VideoPlayer from "~/components/VideoPlayer/index.vue";

defineProps<{ id: string }>();

const dialogRef = ref<HTMLDialogElement | null>(null);
const playerRef = ref<InstanceType<typeof VideoPlayer> | null>(null);

const scrollLock = useBodyScrollLock();

function handleClickOutside(e: MouseEvent) {
  e.stopPropagation();
  close();
}

function handlePointerDown(e: PointerEvent) {
  e.stopPropagation();
  close();
}

function handleEscape(event: KeyboardEvent) {
  if (event.key === "Escape") {
    event.preventDefault();
    close();
  }
}

function play() {
  dialogRef.value?.showModal();
  playerRef.value?.startPlayer();
  addListeners();
  scrollLock.value = true;
}

function close() {
  dialogRef.value?.close();
  playerRef.value?.pausePlayer();
  removeListeners();
  setTimeout(() => {
    scrollLock.value = false;
  }, 0);
}

function addListeners() {
  window.addEventListener("keydown", handleEscape, { capture: true });
  document.addEventListener("click", handleClickOutside, {
    capture: true,
  });
  document.addEventListener("pointerdown", handlePointerDown, {
    capture: true,
  });
}

function removeListeners() {
  window.removeEventListener("keydown", handleEscape, { capture: true });
  document.removeEventListener("click", handleClickOutside, {
    capture: true,
  });
  document.removeEventListener("pointerdown", handlePointerDown, {
    capture: true,
  });
}

onUnmounted(removeListeners);

defineExpose({ play, close });
</script>
