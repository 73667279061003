<template>
  <div class="relative overflow-hidden">
    <client-only>
      <VimeoPlayer
        ref="player"
        :options="{ responsive: true, width: '100%', controls: true }"
        :video-url="url ? url : baseVideoId + id"
      />

      <div
        v-if="slots.preview"
        v-show="!isPlayerActive"
        class="absolute left-0 top-0 h-full w-full object-cover"
      >
        <slot name="preview" />
      </div>

      <div
        v-if="slots.action"
        v-show="!isPlayerActive"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        @click="handleClick"
      >
        <slot name="action" />
      </div>
    </client-only>
  </div>
</template>

<script setup>
const { id, url } = defineProps({
  id: String,
  url: String,
});
const slots = useSlots();

const player = ref(null);
const isPlayerActive = ref(false);
const baseVideoId = "https://player.vimeo.com/video/";

const pausePlayer = () => {
  player.value.pause();
  isPlayerActive.value = false;
};
const startPlayer = () => {
  player.value.play();
  isPlayerActive.value = true;
};

function handleClick() {
  if (isPlayerActive.value) {
    isPlayerActive.value = false;
    pausePlayer();
  } else {
    isPlayerActive.value = true;
    startPlayer();
  }
}

defineExpose({
  pausePlayer,
  startPlayer,
});
</script>
